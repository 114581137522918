import service from '@/util/request'

export function test(data) {
    return service({
        url: '/test/test',
        method: 'get',
        param: data
    })
}

export function login(data) {
    return service({
        url: '/user/login',
        method: 'post',
        data: data
    })
}

export function getExam(data) {
    return service({
        url: '/user/getExam',
        method: 'get',
        params: data
    })
}

export function openExam(data) {
    return service({
        url: '/user/openExam',
        method: 'get',
        params: data
    })
}

export function saveExam(data) {
    return service({
        url: '/user/saveExam',
        method: 'post',
        data: data
    })
}

export function getPaperInfo(data) {
    return service({
        url: '/user/getPaperInfo',
        method: 'get',
        params: data
    })
}
