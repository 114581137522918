import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
import './permission'
Vue.config.productionTip = false
// Vue.prototype.$ws = ws
//app.vue


Vue.use(ElementUI)
Vue.prototype.bus = new Vue()
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
