import Vue from 'vue'
    import Router from 'vue-router'
    Vue.use(Router)

    export default new Router({
        routes: [
            {
                path: '/',
                redirect: 'login'
            },
            {
                path: '/login',
                name: 'login', //登录界面
                component: () => import('@/view/Login')
            },
            {
                path: '/exam',
                component: () => import('@/view/Exam')
            },
            {path: '/examMsg',component: () => import('@/view/examMsg')},
            {path: '/examScore',component: () => import('@/view/examScore')},
            {path: '/paper',component: () => import('@/view/paper')},
        // {
        //     path: '/index',
        //     name: 'index', //首页
        //     component: () => import('@/view/Index')
        // }
    ]
})
