import axios from 'axios'
import {Message} from 'element-ui'
import {tansParams} from "@/util/ruoyi";
import {getToken} from "@/util/auth";
import store from "@/store";

export const isRelogin = { show: false };

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API + "/api/v1", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 0 // request timeout
})

// request interceptor
service.interceptors.request.use((config) => {
        // 对应国际化资源文件后缀
        config.headers['Content-Language'] = 'zh_CN';
        const isToken = (config.headers || {}).isToken === false;
        // 是否需要加密
        if (getToken() && !isToken) {
            config.headers['user-token'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        // FormData数据去请求头Content-Type
        if (config.data instanceof FormData) {
            delete config.headers['Content-Type'];
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {
            if (res.code === 401) {
                Message({
                    message: '登录已过期',
                    type: 'error',
                    duration: 5 * 1000,
                })
                store.dispatch('LogOut').then(() => {
                    location.href = '/';
                })
            } else {
                Message({
                    message: res.msg || 'Error：在这里',
                    type: 'error',
                    duration: 5 * 1000,
                })
                return Promise.reject(res)
            }
        } else {
            return res
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 402:
                    console.log("进来了")
                    break;
            }
        } else {
            Message({
                message: "失败",
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error)

    }
)

export default service
